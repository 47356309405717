@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}


.white-container {
    @apply bg-white border border-border rounded-lg p-8;
}